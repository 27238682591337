import {Injectable} from '@angular/core';
import {first, map} from 'rxjs/operators';
import {UserManagementService} from '../../../shared/services/user-management-service/user-management.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class NavigationAllowedResolverService  {

    constructor(
        private userManagementService: UserManagementService,
        private router: Router
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!route.data.moduleId) {
            return true;
        }

        return this.userManagementService.modules$.pipe(
            map(modules => {
                const activeModule = modules
                    .filter(module => module.canRead)
                    .find(module => {
                        const activeModule = module.id === route.data.moduleId;
                        let activeSubmodule = null;
                        if (module.modules) {
                            activeSubmodule = module.modules
                                .filter(module => module.canRead)
                                .find(submodule => submodule.id === route.data.moduleId);
                        }

                        const response = activeSubmodule || activeModule;

                        return response;
                    });

                if (!activeModule) {
                    setTimeout(() => {
                        this.router.navigateByUrl('/status/unauthorized');
                    });
                    return false;
                }
                return activeModule;
            }),
            first()
        );
    }
}
